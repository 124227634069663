<template>
  <el-date-picker
    placeholder="请选择统计日期"
    unlink-panels
    :value="value"
    type="daterange"
    range-separator="至"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    value-format="yyyy-MM-dd HH:mm:ss"
    :default-time="['00:00:00', '23:59:59']"
    :picker-options="pickerOptions"
    @input="change($event)"
  />
</template>

<script>
export default {
  name: 'DatePicker', //时间选择器，只可以选择1个月以内的
  props: {
    value: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      selectDate: '',
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime()
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 30 * 24 * 3600 * 1000
            const minTime = this.selectDate - one
            const maxTime = this.selectDate + one
            return time.getTime() < minTime || time.getTime() > maxTime
          }
        }
      }
    }
  },
  methods: {
    change(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

