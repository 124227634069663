<template>
  <el-pagination
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="page"
    :page-sizes="[10, 20, 30, 40]"
    :page-size="limit"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
  >
  </el-pagination>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    total: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      limit: 10,
      page: 1
    }
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.$emit('init')
    },
    handleCurrentChange(val) {
      this.page = val
      this.$emit('init')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-pagination {
  text-align: right;
}
</style>

