<template>
  <div>
    <div class="search">
      <span class="el-text">统计日期：</span>
      <DatePicker v-model="time_space" />
      <el-button type="primary" @click="init">查询</el-button>
      <!-- 最长1个月 -->
    </div>
    <el-table :data="tableData" border>
      <el-table-column type="index" width="50" label="序号" />
      <el-table-column label="商户名称">
        <template slot-scope="{ row }">{{ row.merchant_short_name || '-' }}</template>
      </el-table-column>
      <el-table-column label="支付金额">
        <template slot-scope="{ row }">{{ row.pay_currency || '-' }} {{ row.sum_amount || '-' }}</template>
      </el-table-column>
      <el-table-column label="支付笔数">
        <template slot-scope="{ row }">{{ row.count_order || '-' }}</template>
      </el-table-column>
      <el-table-column label="手续费">
        <template slot-scope="{ row }">{{ row.fee_currency || '-' }} {{ row.sum_fee || '-' }}</template>
      </el-table-column>
    </el-table>
    <Pagination :total="total" @init="init" ref="pagination" />
  </div>
</template>

<script>
import { merchantStatistics } from '@/api/api'
import DatePicker from '@/components/date-picker'
import Pagination from '@/components/pagination'

export default {
  name: 'Statistics', //商户统计
  components: { DatePicker, Pagination },
  data() {
    return {
      tableData: [],
      total: 1,
      time_space: [`${new Date().format('yyyy-MM-dd hh:mm:ss').split(' ')[0]} 00:00:00`, new Date().format('yyyy-MM-dd hh:mm:ss')]

    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      const [pagination, time_space] = [this.$refs.pagination, this.time_space]
      const params = {
        page: pagination.page,
        limit: pagination.limit
      }
      if (time_space.length > 0) params.time_space = `${time_space[0]}~${time_space[1]}`
      merchantStatistics(params).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data.list
          this.total = Number(res.data.count)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  .el-button {
    margin-left: 10px;
  }
}
</style>

